import React from 'react'
import Header from '../components/Header'
import Layout from '../layout'
import { graphql, useStaticQuery } from "gatsby";
import './contact.css'
import './global.css'
import Footer from "../components/Footer"

const Page = () => {
    return (<Layout>
        <Header></Header>
        <div className="main">
            <div className="title">
                Me
            </div>
            <div className="content">
              <br/><br/>I mainly focus on modern web application development and look forward to using best practises to refine my work. <br/><br/> I've been working in React, Redux and frontend tech stacks for two years. But I also have experience in backend development such as the Spring framework, Hibernate, Node & Express, MySQL and PIXI.js. I've learned static programming languages like Java in my work but dynamic languages like Python and JavaScript are much more preferred for me.
              <br/><br/>I love playing competitive computer games and rhythm games. I play Dota2, CSGO and Taiko no tatsujin.
              <br/><br/>I love guitar and have been practising fingerstyle for several years.
              <br/><br/> 
            </div>
        </div>
        <Footer></Footer>
    </Layout>)
}

export default Page
// import * as React from "react"

// export default function Component () {
//   return "Hello world"
// }